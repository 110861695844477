import { formRequestChangeFields } from '../../constants/forms/formRequestChange';
import {
  getWithLanguage,
  requestDelete,
  requestGet,
  requestGetParams,
  requestPost,
  requestPostLongTimeout,
  requestPut,
} from './request';
import { accountType } from '../../constants/classificators';
import moment from 'moment';
import { compileUrl } from '../../utils/asyncHelpers';
// Invoices

export const invoiceFetch = id => requestGet(`invoice/${id}/details`);
export const invoiceRecall = id => requestDelete(`invoice/${id}`);
export const invoicesSearch = ({ pager, filter }) =>
  requestPost('invoice/search')(filter, { params: pager });

export const invoiceConfirm = requestPost('invoice');
export const invoiceAdminConfirm = id => requestPost(`invoice/confirm/${id}`)();
export const invoiceGenerate = id => requestPost(`invoice/${id}/recreate`)();

// Notifications

export const notificationsFetch = form => requestPost('notifications')(form);
export const getNotificationsCount = requestGetParams('notifications/count');
export const notificationsAdminApprove = id =>
  requestPut(`measurementDataFiles/${id}`)();
export const notificationsAdminDecline = id =>
  requestDelete(`measurementDataFiles/${id}`);
export const notificationsRemove = id => requestDelete(`notifications/${id}`);

// Register mandate

export const registerMandateCompanyAdd = requestPost(
  'mandate/representative/legalEntity'
);
export const registerMandateCompanyUpdate = requestPut(
  'mandate/representative/legalEntity'
);
export const registerMandateCompanyDelete = id =>
  requestDelete(`mandate/${id}`);
export const registerMandateCompanyFetch = id =>
  requestGet(`legalEntity/${id}/mandates`);

export const registerMandatePersonAdd = requestPost(
  'mandate/representative/physicalPerson'
);
export const registerMandatePersonUpdate = requestPut(
  'mandate/representative/physicalPerson'
);
export const registerMandatePersonDelete = id => requestDelete(`mandate/${id}`);
export const registerMandatePersonFetch = id =>
  requestGet(`legalEntity/${id}/mandates`);
export const registerMandateByLegalEntity = id =>
  requestPost(`mandate/legalEntity/${id}/select`)();

// Register measurement

export const registerMeasurementAdd = requestPost('measurementPoint');
export const avpMeteringPointsFetch = id =>
  requestGet(`legalEntity/${id}/avp/measurementPoints`);
export const getEmployeeRoles = requestGetParams('postlogin/employeeRoles');
export const getAllMandates = requestGetParams('postlogin/allMandates');

// Measurement import
export const measurementImportFilePointAdd = requestPost(
  'measuringDataImport/measurementPoint'
);
export const measurementImportFileUnitAdd = requestPost(
  'measuringDataImport/productionUnit'
);

// Legal entity
export const getLegalEntity = requestGetParams('legalEntity');
export const updateLegalEntity = requestPost('legalEntity');
export const registerMandateFetch = requestGetParams('mandate/search/');
export const registerMandateUpdate = requestPost('mandate');
export const searchLegalEntitiesByName = searchText =>
  requestGet(`legalEntity/search/${searchText}`);
export const searchLegalEntities = ({ pager, filter }) =>
  requestPost('legalEntity/search')(filter, { params: pager });
export const legalEntityActivate = id =>
  requestPut(`legalEntity/${id}/activate`)();
export const legalEntityDeactivate = id =>
  requestPut(`legalEntity/${id}/deactivate`)();
export const companyDataFromBusinessRegisterFetch = registryCode =>
  requestPost('businessRegistry')(null, { params: { registryCode } });
export const producerAvpPointsFetch = id =>
  requestGet(`legalEntity/${id}/avp/measurementPoints/imported`);
export const loadingOperatorAvpPointsFetch = id =>
  requestGet(`legalEntity/${id}/avp/electricMeasurementPoints/imported`);
export const searchProviderLegalEntityByName = name => {
  return requestGetParams(`legalEntity/searchLegalEntityByNameAndRole`)({
    name: name,
    isProvider: true,
  });
};
export const searchTraderProviderLoadingOperatorLegalEntityByName = name => {
  return requestGetParams(`legalEntity/searchLegalEntityByNameAndRole`)({
    name: name,
    isTrader: true,
    isProvider: true,
    isLoadingOperator: true,
  });
};

// Legal entity contacts

export const legalEntityContactsFetch = id =>
  requestGet(`legalEntity/${id}/contacts`);
export const legalEntityContactAdd = requestPost('contactPerson');
export const legalEntityContactUpdate = requestPost('contactPerson');
export const legalEntityContactDelete = id =>
  requestDelete(`contactPerson/${id}`);

// Legal entity documents

export const legalEntityDocumentsFetch = id =>
  requestGet(`legalEntity/${id}/documentation`);
export const legalEntityDocumentsAdd = requestPost('legalEntityDocumentation');
export const legalEntityDocumentsDelete = id =>
  requestDelete(`legalEntityDocumentation/${id}`);

// Production unit

export const legalEntityProductionUnitFetch = id =>
  requestGet(`legalEntity/${id}/productionUnits`);
export const productionUnitAdd = requestPost('productionUnit');
export const productionUnitFetch = id => requestGet(`productionUnit/${id}`);
export const productionUnitFetchByEicwCode = id =>
  requestGet(`productionUnit/eicw/${id}`);
export const productionUnitFetchWithoutHistory = id =>
  requestGet(`productionUnit/removeHistoryFuel/${id}`);
export const productionUnitUpdate = requestPost('productionUnit');
export const productionUnitFuelUpdateRequest = requestPost(
  'productionUnit/updateRequest'
);
export const productionUnitDelete = id => requestDelete(`productionUnit/${id}`);
export const deleteUnitFuel = requestPost(`productionUnit/deleteFuel`);
export const updateFuelsDeleteNonIncluded = requestPost(
  `productionUnit/updateFuelsDeleteNonIncluded`
);
export const productionUnitClassifiers = value =>
  requestGet(`productionUnit/classifiers/${value}`);
export const productionUnitListSearch = ({ pager, filter }) =>
  requestPost('productionUnit/search')(filter, { params: pager });
export const activateUnitPut = id =>
  requestPut(`productionUnit/${id}/activate`)();
export const deactivateUnitPut = id =>
  requestPut(`productionUnit/${id}/deactivate`)();
export const productionUnitFuelsHistoryExists = data =>
  requestPost(`productionUnit/historyExists`)(data);

// Search

export const searchCompany = value => requestGet(`legalEntity/search/${value}`);
export const searchAccount = value =>
  requestGet(`transaction/account/search/${value}`);
export const searchProviderAccountByNameOrProviderAccountNumber = value =>
  requestGet(
    `transaction/account/provider/searchByNameOrAccountNumber/${value}`
  );
export const searchPersonsAndCompanies = value =>
  requestGet(`consumer/search/${value}`);
export const searchRelatedConsumers = value =>
  requestGet(`consumer/search/forSeller/${value}`);
export const searchProductionUnit = value =>
  requestGet(`productionUnit/search/${value}`);
export const searchGridOperators = value =>
  requestGet(`gridOperator/search/${value}`);

// Consumption points

export const consumptionPointListSearch = ({ pager, filter }) =>
  requestPost('measurementPoint/search')(filter, { params: pager });

// production Unit Documents

export const productionUnitDocumentsFetch = id =>
  requestGet(`productionUnit/${id}/documentation`);
export const productionUnitDocumentsAdd = requestPost('productionUnitDocument');
export const productionUnitDocumentsDelete = id =>
  requestDelete(`productionUnitDocument/${id}`);

// production Unit Certificates

export const productionUnitCertificatesFetch = id =>
  requestGet(`productionUnit/${id}/certificates`);
export const productionUnitCertificatesAdd = requestPost(
  'productionUnitCertificate'
);

// production unit measurement point

export const productionUnitMeasurementFetch = id =>
  requestGet(`productionUnit/${id}/measurementPoint`);

export const productionUnitMeasurementInactiveFetch = id =>
  requestGet(`productionUnit/${id}/inactiveMeasurementPointList`);

// consumer

export const consumerCreate = requestPost('consumer');
export const consumerFetch = id => requestGet(`consumer/${id}`);

// physical person

export const userFetch = requestGetParams('postlogin/person');
export const userSelectedMandateFetch = requestGetParams('mandate/current');
export const userMandatesFetch = requestGetParams('postlogin/allMandates');
export const userSelectMandate = id => requestPost(`mandate/${id}/select`)();
export const userLogout = requestPost('logout');

// application settings

export const applicationSettingsFetch = requestGetParams('postlogin/settings');

// metering point

export const meteringReviewFetch = id =>
  requestGet(`measurementDataFiles/${id}`);
export const meteringPointFetch = id => requestGet(`/measurementPoint/${id}`);
export const electricMeteringPointFetch = id =>
  requestGet(`/electricMeasurementPoint/${id}`);
export const meteringPointAdd = requestPost('measurementPoint/productionUnit');
export const meteringPointUpdate = requestPost(
  'measurementPoint/productionUnit'
);
export const consumptionPointUpdateOrAdd = requestPost(
  'measurementPoint/consumer'
);
export const meteringPointDelete = id =>
  requestDelete(`measurementPoint/${id}`);
export const meteringPointAmountsConfirm = ({
  meteringPointId: measurementPointId,
  year,
  month,
}) =>
  requestPost(`measurementPoint/${measurementPointId}/confirmAmounts`)(
    {},
    {
      params: {
        measurementPointId,
        year,
        month,
      },
    }
  );
export const saveHydrogenFuelData = hydrogenFuelData => {
  return requestPost('productionUnitHydrogenFuel')(hydrogenFuelData);
};

export const confirmHydrogenFuelData = hydrogenFuelData => {
  return requestPost('productionUnitHydrogenFuel/confirm')(hydrogenFuelData);
};

export const meteringPointAgrDataFetch = ({
  meteringPointId: measurementPointId,
  year,
  month,
}) =>
  requestPost('monthlyAggregatedData')(
    {},
    { params: { measurementPointId, year, month } }
  );

export const hydrogenMeasurementYearsFetch = productionUnitId =>
  requestGet(
    compileUrl('hydrogenMeasurementData/years', {
      productionUnitId,
    })
  );

export const hydrogenMeasurementDataHistoryForYearFetch = ({
  productionUnitId,
  year,
}) =>
  requestGet(
    compileUrl('hydrogenMeasurementData/history', {
      productionUnitId,
      year,
    })
  );

export const hydrogenFuelDataFetch = ({ productionUnitId, year, month }) =>
  requestGet(
    compileUrl('productionUnitHydrogenFuel', {
      productionUnitId: productionUnitId,
      year,
      month,
    })
  );

export const fetchHydrogenMeasurementData = ({
  productionUnitId,
  year,
  month,
}) =>
  requestGet(
    compileUrl('hydrogenMeasurementData', {
      productionUnitId,
      year,
      month,
    })
  );

export const fetchLastHydrogenMeasurementSurplus = ({
  productionUnitId,
  year,
  month,
}) =>
  requestGet(
    compileUrl('hydrogenMeasurementData/surplus', {
      productionUnitId,
      year,
      month,
    })
  );

export const hydrogenIssueGos = ({ productionUnitId, year, month }) =>
  requestPost('hydrogenMeasurementData/issueGos')(
    {},
    { params: { productionUnitId, year, month } }
  );

export const isHydrogenGosIssued = ({ productionUnitId, year, month }) =>
  requestGet(
    compileUrl('hydrogenMeasurementData/isGosIssued', {
      productionUnitId,
      year,
      month,
    })
  );

export const hydrogenMeasurementDataAdd = hydrogenMeasurementData =>
  requestPost('hydrogenMeasurementData')(hydrogenMeasurementData);
export const hydrogenMeasurementDataConfirm = hydrogenMeasurementData =>
  requestPost('hydrogenMeasurementData/confirm')(hydrogenMeasurementData);

export const electricMeteringPointAgrDataFetch = ({
  meteringPointId: measurementPointId,
  year,
}) =>
  requestPost('electricMeasurementPoint/monthlyAggregatedData')(
    {},
    {
      params: {
        measurementPointId,
        year,
      },
    }
  );
export const electricIssuedTSCertificatesAmountsDataFetch = ({ year }) =>
  requestPost('electricMeasurementPoint/issuedElectricGosesSummary')(
    {},
    { params: { year } }
  );

export const consumerPointFetch = id =>
  requestGet(`consumer/${id}/measurementPoint`);
export const loggedConsumerPointFetch = requestGetParams(
  'consumer/measurementPoint'
);
export const activatePointPut = id =>
  requestPut(`measurementPoint/${id}/activate`)();
export const deactivatePointPut = id =>
  requestPut(`measurementPoint/${id}/deactivate`)();

export const pointGenerateGo = id =>
  requestPost(`measurementPoint/${id}/generateGo`)();

export const avpConsumerPointsFetch = requestGetParams(
  'measurementPoint/search/avp/'
);

// transactions

export const transactionsSearch = ({ pager, filter }) =>
  requestPost('transaction/search')(filter, {
    params: {
      ...pager,
      sort: 'transactionCreateTime,desc',
    },
  });
export const transactionFetch = id => requestGet(`transaction/${id}`);
export const transactionStatement = id =>
  requestGet(`transaction/${id}/cancellationStatement`);
export const transactionRecall = (id, recallReason) =>
  requestPost(`transaction/${id}/recall`)(recallReason);

export const searchTAEntries = data => {
  const { expirationDate } = data.filter;
  const { page, size } = data.pager;
  return requestGet(
    compileUrl('tradingAccount/overview', {
      expirationDate: expirationDate
        ? moment(expirationDate, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY')
        : null,
      page,
      size,
    })
  );
};

export const searchTATransactions = data => {
  const { transactionDateFrom, transactionDateTo } = data.filter;
  const { page, size } = data.pager;
  return requestGet(
    compileUrl('tradingAccount/transactions', {
      ...data.filter,
      page,
      size,
      transactionDateFrom: transactionDateFrom
        ? moment(transactionDateFrom, 'DD.MM.YYYY HH:mm:ss').format(
            'DD.MM.YYYY'
          )
        : null,
      transactionDateTo: transactionDateTo
        ? moment(transactionDateTo, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY')
        : null,
    })
  );
};

export const searchAccountGOSAgreements = requestPost('tsAgreement/search');
export const agreementFetch = agreementId =>
  requestGet(`tsAgreement/${agreementId}`);

export const agreementFetchByTransaction = tsPreTransactionId =>
  requestGet(`tsreserve/tpt/${tsPreTransactionId}`);
export const agreementWithdrawFetch = agreementWithdrawId =>
  requestGet(`tsAgreement/withdraw/${agreementWithdrawId}`);
export const tsAgreementPost = data => requestPost('tsAgreement')(data);
export const tsAgreementWithdraw = data =>
  requestPost('tsAgreement/withdraw')(data);
export const tsAgreementConfirm = (id, value) =>
  requestPost(`tsAssignment/${id}/complete`)(value);
export const tsAgreementDelete = id => requestDelete(`tsAgreement/${id}`);
export const tsAgreementQueueNumFetch = data =>
  requestPost('tsAgreement/queueNum')(data);
export const transportStatisticsTransfer = (id, data) =>
  requestPost(`tsAssignment/${id}/transaction/complete`)(data);
export const reservedItemsFetch = assignmentId =>
  requestGet(`tsreserve/overview/${assignmentId}`);
export const searchAccountGOSReport = requestPost('accountGOS/searchGOSReport');
export const searchAccountGOSTsAssignments = requestPost('tsAssignment/search');
export const getGOSTsAssignmentCount = requestGetParams('tsAssignment/count');
export const tradingAccountTransactionFetch = (id, type) =>
  requestGet(`tradingAccount/transaction/${type}/${id}`);
export const transactionGosRecall = (id, recallReason) =>
  requestPost(`transactionForGos/${id}/recall`)(recallReason);
export const completeAssignment = (id, isCompleted) =>
  requestPost(`tsAssignment/${id}/complete`)(isCompleted);
export const getAvailableStatistics = id =>
  requestGet(`tsAgreement/${id}/available-ts`);
export const reserveTS = data => requestPost('tsreserve/reserve')(data);
export const agreementTransactionsListFetch = (agreementId, pager) =>
  requestPost(`tsAgreement/${agreementId}/transactions`)(pager);
export const transportStatisticsDetailsFetch = (agreementId, statisticsId) =>
  requestGet(`tsAgreement/${agreementId}/transactions/${statisticsId}`);

// reports

export const reportGridItemsFetch = ({ year }) =>
  requestGet(`grid/${year}/report`);

export const reportGosItemsFetch = params => {
  return requestGetParams(`report/renewableEnergy`)({
    ...params,
    sort: 'transactionDate',
  });
};

export const reportElectricConsumptionReportItemsFetch = params => {
  return requestGetParams(`electricReport/consumption`)(params);
};

// request change

export const productionUnitChangeRequest = values =>
  requestPost(
    `productionUnit/${
      values[formRequestChangeFields.RELATED_OBJECT_ID]
    }/requestChanges`
  )(values);
export const legalEntityChangeRequest = values =>
  requestPost(
    `legalEntity/${
      values[formRequestChangeFields.RELATED_OBJECT_ID]
    }/requestChanges`
  )(values);
export const meteringPointChangeRequest = values =>
  requestPost(
    `measurementPoint/${
      values[formRequestChangeFields.RELATED_OBJECT_ID]
    }/requestChanges`
  )(values);

// certificates / account

export const accountOverviewSearchFetch = requestPost('account/go/search');
export const accountReportSearchFetch = requestPost(
  'account/go/periodicReport'
);
export const accountGroupedCertificatesFetch = requestPost('account/go/search');
export const accountCancelGroupedCertificatesFetch = requestPost(
  'account/go/cancellation/search'
);

export const goAmountFetch = requestPost('account/go/cancel/amount');
export const cancelCertificatesPost = requestPostLongTimeout(
  'account/go/cancel'
);
export const transferCertificatesPost = requestPost('account/go/transfer');

export const reportGoItemsFetch = params =>
  requestGetParams(`account/go/report`)(params);

// classifiers

export const classifiersGlobalFetch = lang => getWithLanguage('classifier/db', lang);
export const classifierTranslationsGlobalFetch = requestGetParams(
  'classifier/translations'
);
export const classifiersGeneralFetch = code =>
  requestGet(`classifier/db/${code}`);
export const classifiersGeneralChange = (code, data) =>
  requestPost(`classifier/db/${code}`)(data);
export const classifiersSubFetch = classifierCode =>
  requestGet(`classifier/sub/${classifierCode}`);
export const classifiersSubChange = (code, data) =>
  requestPost(`classifier/sub/${code}`)(data);
export const classifiersSubDelete = (code, subCode) =>
  requestDelete(`classifier/subdel/${code}/${subCode}`);
export const classifiersSubValuesFetch = classifierId =>
  requestGet(`classifier/subval/${classifierId}`);
export const classifiersSubValuesChange = (code, data) =>
  requestPost(`classifier/subval/${code}`)(data);
export const classifiersSubValuesDelete = (code, subCode) =>
  requestDelete(`classifier/subvaldel/${code}/${subCode}`);
export const classifiersListFetch = requestGetParams('classifier/list');
export const classifiersItemFetch = ({ classifierId, year }) =>
  requestGet(`monthlyClassifiers/${classifierId}/${year}`);
export const classifiersPriceChange = requestPost('monthlyClassifiers/add');
export const classifiersTaxFetch = requestGetParams('taxClassifier');
export const classifiersTaxChange = requestPost('taxClassifier');
export const classifiersInvoiceConfirmationFetch = requestGetParams(
  'invoiceConfirmationClassifier'
);
export const classifiersInvoiceConfirmationChange = requestPost(
  'invoiceConfirmationClassifier'
);

export const classifiersGoAutoFetch = requestGetParams(
  'classifier/cancellationDate'
);
export const classifiersGoAutoChange = requestPost(
  'classifier/cancellationDate'
);

export const classifiersGoExpirationFetch = requestGetParams(
  'classifier/goExpirationChangeDate'
);
export const classifiersGoExpirationChange = requestPost(
  'classifier/goExpirationChangeDate'
);

export const classifiersEAVPLoadFetch = requestGetParams(
  'classifier/eavpLoadDataCron'
);
export const classifiersEAVPLoadChange = requestPost(
  'classifier/eavpLoadDataCron'
);

export const classifiersGosAutoFetch = requestGetParams(
  'classifier/gosExpirationDate'
);
export const classifiersGosAutoChange = requestPost(
  'classifier/gosExpirationDate'
);

export const classifiersInvoiceDateFetch = requestGetParams(
  'classifier/invoiceGenerationDate'
);
export const classifiersInvoiceDateChange = requestPost(
  'classifier/invoiceGenerationDate'
);

export const classifiersSubsidyDateFetch = requestGetParams(
  'classifier/subsidyDate'
);
export const classifiersSubsidyDateChange = requestPost(
  'classifier/subsidyDate'
);

export const classifiersConsumptionReportEndDayFetch = requestGetParams(
  'classifier/consumptionReportEndDay'
);
export const classifiersConsumptionReportEndDayChange = requestPost(
  'classifier/consumptionReportEndDay'
);

export const classifiersEleringFetch = requestGetParams('classifier/elering');
export const classifiersEleringChange = requestPost('classifier/elering');

export const classifiersGridOperatorListFetch = () =>
  requestGet('classifier/gridOperator');
export const classifiersGridOperatorFetch = code =>
  requestGet(`classifier/gridOperator/${code}`);
export const classifiersGridOperatorDelete = code =>
  requestDelete(`classifier/gridOperator/${code}`);
export const classifiersGridOperatorChange = requestPost(
  'classifier/gridOperator'
);

export const classifiersSellerListFetch = () => requestGet('classifier/seller');
export const classifiersSellerFetch = code =>
  requestGet(`classifier/seller/${code}`);
export const classifiersSellerDelete = code =>
  requestDelete(`classifier/seller/${code}`);
export const classifiersSellerChange = requestPost('classifier/seller');

export const classifiersLegalEntityConsumptionTypeListFetch = () =>
  requestGet('classifier/legalEntityConsumptionType');
export const classifiersLegalEntityConsumptionTypeFetch = id =>
  requestGet(`classifier/legalEntityConsumptionType/${id}`);
export const classifiersLegalEntityConsumptionTypeCreate = data =>
  requestPost(`classifier/legalEntityConsumptionType`)(data);
export const classifiersLegalEntityConsumptionTypeUpdate = (id, data) =>
  requestPut(`classifier/legalEntityConsumptionType/${id}`)(data);
export const classifiersLegalEntityConsumptionTypeDelete = id =>
  requestDelete(`classifier/legalEntityConsumptionType/${id}`);

export const classifiersAggregatedDataConfirmationFetch = requestGetParams(
  'classifier/aggregatedDataConfirmationCron'
);
export const classifiersAggregatedDataConfirmationChange = requestPost(
  'classifier/aggregatedDataConfirmationCron'
);

// consumer reports

export const consumerGoSearch = ({ pager, filter }) =>
  requestPost('consumer/go/search')(filter, { params: pager });
export const consumerChartYearFetch = () =>
  requestGet('consumer/consumption/year');
export const consumerChartSellerFetch = seller =>
  requestGet(`consumer/consumption/amongSeller?seller=${seller}`);
export const consumerSellersFetch = () => requestGet('consumer/sellers');

// manual go issuing

export const recalculateGoPost = requestPost('transaction/manuallyIssuing');
export const transactionManualIssue = id =>
  requestPost(`transaction/${id}/manualIssue`)();

// manual electric TS issuing
export const refreshMeasurementDataPost = (id, data) =>
  requestPost(`legalEntity/${id}/avp/electricMeasurementData/refresh`)(data);
export const calculateMeasurementDataPost = (id, data) =>
  requestPost(`legalEntity/${id}/avp/electricMeasurementData/calculate`)(data);

// vds tools

export const sendBioFuelReportToVDS = data =>
  requestPost('/BiofuelToReport')(data);
export const sendElectricityReportToVDS = data =>
  requestPost('/ElectricityToReport')(data);
export const sendStatisticsRequestToVDS = data =>
  requestPost('/StatisticsTransfer')(data);
export const sendTransmissionCancellationRequestToVDS = data =>
  requestPost('/TransferCancel')(data);
export const sendSalesCancellationRequestToVDS = data =>
  requestPost('/OfferCancel')(data);
export const getProviders = () =>
  searchLegalEntities({
    pager: { page: 0, size: 1000 },
    filter: { legalEntityType: accountType.PROVIDER },
  });
export const getStatisticsSalesRequestFromVDS = selectedTrader =>
  requestGet('/StatisticsOfferRequest/' + selectedTrader);

// loading unit
export const loadingUnitAdd = requestPost('loadingUnit');
export const loadingUnitUpdate = requestPost('loadingUnit');
export const loadingUnitDelete = id => requestDelete(`loadingUnit/${id}`);
export const legalEntityLoadingUnitFetch = id =>
  requestGet(`legalEntity/${id}/loadingUnits`);
export const loadingUnitFetch = id => requestGet(`loadingUnit/${id}`);

export const loadingUnitListSearch = ({ pager, filter }) =>
  requestPost('loadingUnit/search')(filter, { params: pager });

export const electricMeasurementPointsFetch = id =>
  requestGet(`loadingUnit/${id}/measurementPoints`);
export const loadingUnitsAllGet = () => requestGet('loadingUnit/all');
export const activateLoadingUnit = loadingUnitId =>
  requestPost(`loadingUnit/${loadingUnitId}/activate`)();
export const deactivateLoadingUnit = loadingUnitId =>
  requestPost(`loadingUnit/${loadingUnitId}/deactivate`)();

export const tradingAccountOverviewDetailsPost = data =>
  requestPost('tradingAccount/overview/details')({ rows: data });
export const tradingAccountAuctionOverviewDetailsPost = data =>
  requestPost('tradingAccount/auction/overview/details')({ rows: data });
export const tradingAccountTransferPost = requestPost(
  'tradingAccount/transfer'
);
export const tradingAccountLockPost = requestPost('tradingAccount/lock');

// statistics
export const accountStatisticsGet = data =>
  requestPost('tradingAccount/overview/details')(data);
export const transferStatisticsPost = data =>
  requestPost(`statistics/transfer`)(data);
export const lockStatisticsPost = data => requestPost(`statistics/lock`)(data);

// vds messages
export const VDSMessagesListSearch = ({ pager, filter }) =>
  requestPost('vdsMessages/search')(filter, { params: pager });
export const vdsMessageXMLFetch = requestGetParams('vdsMessages/requestXml');
export const vdsMessageXMLSend = (id, data) =>
  requestPost(`vdsMessages/${id}/send`)(data);

// electricMeasurementPoint
export const electricMeteringPointListSearch = ({ pager, filter }) =>
  requestPost('electricMeasurementPoint/search')(filter, { params: pager });

// ts auctions
export const accountGOSAuctionsSearch = requestPost('tsAuction/search');
export const completedAuctionsResultFetch = () =>
  requestGet('tsAuction/last-10');
export const tradingAccountAuctionPost = data => requestPost('tsAuction')(data);
export const tsAuctionBidDataFetch = id => requestGet(`tsAuction/bid/${id}`);
export const tsAuctionBidPost = data => requestPost('tsAuction/bid')(data);
export const auctionFetch = id => requestGet(`tsAuction/${id}`);
export const auctionDetailsFetch = id => requestGet(`tsAuction/details/${id}`);
export const statisticsDetailsFetch = id =>
  requestGet(`tsAuction/statistics/${id}`);
export const statisticOverviewDetailsFetch = (id, fuelType) =>
  requestGet(`tsAuction/statistics/overview/${id}/${fuelType}`);
export const getTsAuctionHistoryCreated = pager =>
  requestPost('tsAuction/history/created')(pager);
export const getTsAuctionHistoryWon = pager =>
  requestPost('tsAuction/history/won')(pager);
export const getTsAuctionHistoryEnded = pager =>
  requestPost('tsAuction/history/ended')(pager);
export const auctionDelete = id => requestDelete(`tsAuction/${id}`);
