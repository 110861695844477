import { createAsyncAction, createResetAction } from '../../utils/asyncHelpers';
import {
  fetchHydrogenMeasurementData,
  hydrogenMeasurementDataAdd,
  hydrogenMeasurementDataConfirm,
  fetchLastHydrogenMeasurementSurplus,
  isHydrogenGosIssued,
} from '../actionHelpers/endpoints';
import {
  ADD_HYDROGEN_MEASUREMENT_DATA,
  CONFIRM_HYDROGEN_MEASUREMENT_DATA,
  FETCH_IS_GOS_ISSUED,
  FETCH_HYDROGEN_MEASUREMENT_DATA,
  FETCH_PREVIOUS_HYDROGEN_MEASUREMENT_SURPLUS,
} from '../../constants/actionTypes/equipmentAddHydrogenMeasurementDataActionTypes';

export const fetchHydrogenMeasurementDataForProductionPeriod = createAsyncAction(
  FETCH_HYDROGEN_MEASUREMENT_DATA,
  fetchHydrogenMeasurementData
);

export const fetchPreviousHydrogenMeasurementSurplus = createAsyncAction(
  FETCH_PREVIOUS_HYDROGEN_MEASUREMENT_SURPLUS,
  fetchLastHydrogenMeasurementSurplus
);

export const fetchHydrogenGosIssued = createAsyncAction(
  FETCH_IS_GOS_ISSUED,
  isHydrogenGosIssued
);

export const resetHydrogenGosIssued = createResetAction(FETCH_IS_GOS_ISSUED);

export const resetPreviousHydrogenMeasurementSurplus = createResetAction(
  FETCH_PREVIOUS_HYDROGEN_MEASUREMENT_SURPLUS
);

export const addHydrogenMeasurementData = createAsyncAction(
  ADD_HYDROGEN_MEASUREMENT_DATA,
  hydrogenMeasurementDataAdd
);

export const confirmHydrogenMeasurementData = createAsyncAction(
  CONFIRM_HYDROGEN_MEASUREMENT_DATA,
  hydrogenMeasurementDataConfirm
);
