import { createAsyncAction } from '../../utils/asyncHelpers';
import {
  HYDROGEN_MEASUREMENT_DATA_HISTORY_FOR_YEAR_FETCH,
  HYDROGEN_MEASUREMENT_YEARS_FETCH, SELECT_YEAR,
} from '../../constants/actionTypes/hydrogenActionTypes';
import { hydrogenMeasurementDataHistoryForYearFetch, hydrogenMeasurementYearsFetch } from '../actionHelpers/endpoints';
import { createAction } from 'redux-actions';
import { getHistoryYears } from '../../reducers/equipmentAddMeasurementDataReducer';

export const fetchYears = createAsyncAction(
  HYDROGEN_MEASUREMENT_YEARS_FETCH,
  hydrogenMeasurementYearsFetch
)

export const getHistoryForYear = createAsyncAction(
  HYDROGEN_MEASUREMENT_DATA_HISTORY_FOR_YEAR_FETCH,
  hydrogenMeasurementDataHistoryForYearFetch
)

export const changeYear = createAction(SELECT_YEAR);

export const loadInitialData = (productionUnitId) => async (dispatch, getState) => {
  await dispatch(fetchYears(productionUnitId));

  const year = getHistoryYears(getState()).toJS()[0]

  dispatch(changeYear(year));

  dispatch(getHistoryForYear({productionUnitId, year}))
}

