import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Spin, Select, Table, Button } from 'antd';
import {
  getHistoryYears,
  getLoadingYears,
  getLoadingHistory,
  getHistory, getSelectedYear,
} from '../../reducers/equipmentAddMeasurementDataReducer';
import { getLocale } from '../../reducers/settingsReducer';
import {
  getHistoryForYear,
  fetchYears,
  changeYear, loadInitialData,
} from '../../actions/actionCreators/measurementDataHistoryActionCreators';
import { measurementDataFormFields } from '../../constants/formsAddEquipment';
import { downloadFileByGetURL } from '../../utils/files';
import { links } from '../../utils';

const {Option} = Select;

export class HydrogenMeasurementDataHistory extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    productionUnit: PropTypesImmutable.map,
    isAdmin: PropTypes.bool.isRequired,
    showRequestChangeModal: PropTypes.func,
    isHydrogenContext: PropTypes.bool,
    fetchYears: PropTypes.func.isRequired,
    fetchHistoryForYear: PropTypes.func.isRequired,
  };


  componentDidMount() {
    const {
      productionUnit,
      loadInitialData
    } = this.props;

    const productionUnitId = productionUnit.get('id');

    loadInitialData(productionUnitId)

  }

  getColumns() {
    const { t } = this.props;
    return [
      {
        title: '',
        children: [
          {
            title: t('measurementData.month.name'),
            dataIndex: measurementDataFormFields.MONTH,
            render: (value) => {
              return t('measurementData.month.'+ value);
            }
          }
        ]
      },
      {
        title: t('measurementData.electricitySubtitle'),
        className: 'left-border',
        children: [
          {
            title: t('measurementData.rfbnoElectricity'),
            className: 'left-border',
            dataIndex: measurementDataFormFields.RFBNO_ELECTRICITY,
            render: (value) => {
              return value.toFixed(3)
            }
          },
          {
            title: t('measurementData.otherRenewableElectricity'),
            dataIndex: measurementDataFormFields.OTHER_RENEWABLE_ELECTRICITY,
            render: (value) => {
              return value.toFixed(3)
            }
          },
          {
            title: t('measurementData.gridElectricity'),
            dataIndex: measurementDataFormFields.GRID_ELECTRICITY,
            render: (value) => {
              return value.toFixed(3)
            }
          },
          {
            title: t('measurementData.fossilElectricity'),
            dataIndex: measurementDataFormFields.FOSSIL_ELECTRICITY,
            render: (value) => {
              return value.toFixed(3)
            }
          }
        ],

      },
      {
        title: t('measurementData.hydrogenSubtitle'),
        className: 'left-border',
        children: [
          {
            title: t('measurementData.producedHydrogen'),
            className: 'left-border',
            dataIndex: measurementDataFormFields.PRODUCED_HYDROGEN,
            render: (value) => {
              return value.toFixed(3)
            }
          },
          {
            title: t('measurementData.surplus'),
            dataIndex: measurementDataFormFields.SURPLUS_HISTORY,
            render: (value) => {
              return value.toFixed(3)
            }
          },
          {
            title: t('measurementData.total'),
            dataIndex: measurementDataFormFields.TOTAL_HISTORY,
            render: (value) => {
              return value.toFixed(3)
            }
          }
        ]
      },
      {
        title: t('measurementData.goIssuanceSubtitle'),
        className: 'left-border',
        children: [
          {
            title: t('measurementData.rfnboHydrogen'),
            className: 'left-border',
            dataIndex: measurementDataFormFields.RFNBO_HYDROGEN_HISTORY,
          },
          {
            title: t('measurementData.otherRenewableHydrogen'),
            dataIndex: measurementDataFormFields.OTHER_RENEWABLE_HYDROGEN_HISTORY,
          },
          {
            title: t('measurementData.fossilHydrogen'),
            dataIndex: measurementDataFormFields.FOSSIL_HYDROGEN_HISTORY,
          }
        ]
      }
    ]
  }


  render() {
    const {
      loading,
      yearsList,
      selectedYear,
      history,
      t
    } = this.props;
    let yearsjs =[]
    if (yearsList)  {
      yearsjs = yearsList.toJS()
    }
    let dataSource = [];
    if(history) {
      dataSource = history.toJS();
    }
    return (
      <div>
        <h2>
          {t('measurementData.history')}
        </h2>
        <Spin spinning={loading}>
          <Select
            className={'w-20 pb-2'}
            value={selectedYear}
            onChange={value => this.yearChange(value)}>
            {yearsjs.map((element, index) => <Option key={index} value={element}>{element}</Option>)}
          </Select>
          <Table
            rowKey={measurementDataFormFields.ID}
            dataSource={dataSource}
            columns={this.getColumns()}
            pagination={false}
          />
          <div className={"component-table-actions"}>
            <Button onClick={this.downloadCsv}>
              {t('measurementData.csv')}
            </Button>
          </div>
        </Spin>
      </div>
    );
  }

  yearChange(value) {
    const {
      changeYear,
      fetchHistoryForYear,
      productionUnit
    } = this.props;

    changeYear(value);
    fetchHistoryForYear({ 'productionUnitId': productionUnit.get('id'), 'year': value });
  }

  downloadCsv = () => {
    const { locale, productionUnit, selectedYear } = this.props;

    const params = {
      productionUnitId: productionUnit.get('id'),
      year: selectedYear,
      locale: locale
    }

    downloadFileByGetURL(links.hydrogenMeasurementDataExport, params, 'measurement-data-history.csv', 'get')

  }
}

const mapStateToProps = (state) => {


  return {
    selectedYear: getSelectedYear(state),
    history: getHistory(state),
    yearsList: getHistoryYears(state),
    loading: getLoadingHistory(state) || getLoadingYears(state),
    locale: getLocale(state),
  };
};

const mapDispatchToProps = {
  fetchYears: fetchYears,
  fetchHistoryForYear: getHistoryForYear,
  changeYear: changeYear,
  loadInitialData: loadInitialData
};

export default connect(mapStateToProps, mapDispatchToProps)(HydrogenMeasurementDataHistory);



