import { selectOwner as selectOwnerPure } from '../pureActions/productionUnitAddActions';
import { createAsyncAction } from '../../utils/asyncHelpers';
import {
  hydrogenFuelDataFetch,
  hydrogenIssueGos,
} from '../actionHelpers/endpoints';
import {
  HYDROGEN_FUEL_DATA_FETCH,
  HYDROGEN_ISSUE_GOS,
} from '../../constants/actionTypes/hydrogenActionTypes';

export const selectOwner = id => dispatch => dispatch(selectOwnerPure(id));

export const fetchHydrogenFuelDataForProductionPeriod = createAsyncAction(
  HYDROGEN_FUEL_DATA_FETCH,
  hydrogenFuelDataFetch
);

export const issueHydrogenGos = createAsyncAction(
  HYDROGEN_ISSUE_GOS,
  hydrogenIssueGos
);
