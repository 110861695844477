import { handleActions } from 'redux-actions';
import { createAsyncReducer } from '../utils/asyncHelpers';
import {
  ADD_HYDROGEN_MEASUREMENT_DATA,
  CONFIRM_HYDROGEN_MEASUREMENT_DATA,
  FETCH_IS_GOS_ISSUED,
  FETCH_HYDROGEN_MEASUREMENT_DATA,
  FETCH_PREVIOUS_HYDROGEN_MEASUREMENT_SURPLUS,
} from '../constants/actionTypes/equipmentAddHydrogenMeasurementDataActionTypes';
import {
  HYDROGEN_MEASUREMENT_DATA_HISTORY_FOR_YEAR_FETCH,
  HYDROGEN_MEASUREMENT_YEARS_FETCH,
} from '../constants/actionTypes/hydrogenActionTypes';
import { Map } from 'immutable';
import { changeYear } from '../actions/actionCreators/measurementDataHistoryActionCreators';

const equipmentAddMeasurementDataReducer = handleActions(
  {
    [changeYear]: (state, { payload }) => state.set('selectedYear', payload),
    ...createAsyncReducer(FETCH_HYDROGEN_MEASUREMENT_DATA),
    ...createAsyncReducer(
      FETCH_PREVIOUS_HYDROGEN_MEASUREMENT_SURPLUS,
      'surplus'
    ),
    ...createAsyncReducer(FETCH_IS_GOS_ISSUED, 'isGosIssued'),
    ...createAsyncReducer(CONFIRM_HYDROGEN_MEASUREMENT_DATA),
    ...createAsyncReducer(ADD_HYDROGEN_MEASUREMENT_DATA),
    ...createAsyncReducer(HYDROGEN_MEASUREMENT_YEARS_FETCH, 'years'),
    ...createAsyncReducer(
      HYDROGEN_MEASUREMENT_DATA_HISTORY_FOR_YEAR_FETCH,
      'history'
    ),
  },
  Map({ loading: false, data: null, error: '', selectedYear: null })
);

export default equipmentAddMeasurementDataReducer;

export const getHydrogenMeasurementData = state =>
  state.getIn(['hydrogenMeasurementData', 'data']);

export const getPreviousHydrogenMeasurementSurplus = state =>
  state.getIn(['hydrogenMeasurementData', 'surplus']);

export const getIsGosIssued = state =>
  state.getIn(['hydrogenMeasurementData', 'isGosIssued']);

export const getLoadingMeasurementData = state =>
  state.getIn(['hydrogenMeasurementData', 'loading']);

export const getLoadingHistory = state =>
  state.getIn(['hydrogenMeasurementData', 'history', 'loading']);

export const getLoadingYears = state =>
  state.getIn(['hydrogenMeasurementData', 'years', 'loading']);

export const getHistoryYears = state =>
  state.getIn(['hydrogenMeasurementData', 'years', 'data']);

export const getHistory = state =>
  state.getIn(['hydrogenMeasurementData', 'history', 'data']);

export const getSelectedYear = state =>
  state.getIn(['hydrogenMeasurementData', 'selectedYear']);
