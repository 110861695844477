// @flow

import {
  classifiersGlobalFetch,
  classifierTranslationsGlobalFetch,
} from '../actions/actionHelpers/endpoints';
import classificatorTypes from '../constants/classificators';
import { classifierGeneralItemPropertiesField } from '../constants/classifier';
import _ from 'lodash';
import i18n from '../i18n';

type ClassifierItem = {
  [string]: number,
};

type ClassifierItemUnparsedLine = {
  active: boolean,
};

type ClassifierItemUnparsed = {
  lines: [ClassifierItemUnparsedLine],
  translation: string,
  properties: [],
  code: string,
};

type ClassifierTranslationItem = {
  [string]: number,
};

type ClassifierTranslationItemUnparsed = {
  key: string,
  translation: string,
};

class ClassifiersStore {
  _classifiers: ClassifierItem = {};
  _classifierTranslations: ClassifierTranslationItem = {};

  getClassifierByType = (type: string) => _.get(this._classifiers, type);

  getClassifierByKey = (key: string) =>
    _.get(this._classifierTranslations, key);

  getConsumptionUsageProperties = (code: string) => {
    const classifier = this.getClassifierByType(
      classificatorTypes.consumptionUsage
    );
    const classifierElement = classifier.find(
      item => this.getCode(item) === code
    );
    return classifierElement ? this.getProperties(classifierElement) : null;
  };

  getBooleanProperty = (code: string) => {
    const classifier = this.getClassifierByType(code).find(
      item => this.getCode(item) === code
    );
    const properties = this.getProperties(classifier);
    const property = properties.find(
      property => this.getCode(property) === 'boolean'
    );
    return property.value === 'enabled';
  };

  getResPercentage = () => {
    const classifier = this.getClassifierByType(
      classificatorTypes.environmentalBoardReport
    );
    const classifierElement = classifier.find(
      item => this.getCode(item) === 'RES_PERCENT'
    );
    return Number(classifierElement.properties[0].value);
  };

  getFuelTypeColor = (code: string) => {
    const classifier = this.getClassifierByType(classificatorTypes.fuelType);
    const classifierElement = classifier.find(
      item => this.getCode(item) === code
    );
    const properties = classifierElement
      ? this.getProperties(classifierElement)
      : null;

    const color = properties
      ? properties.find(item => this.getCode(item) === 'fuel_type_color')
      : null;

    return (color && color.value) || '#003E51';
  };

  getInvoiceConfirmationText = (): string =>
    this.getClassifierByType(classificatorTypes.invoiceConfirmation);

  update = async () => {
    const classifiers = await classifiersGlobalFetch(i18n.language);
    const classifierTranslations = await classifierTranslationsGlobalFetch();

    if (classifiers && classifiers.data) {
      classifiers.data.forEach(item => {
        const code = this.getCode(item);

        if (code === classificatorTypes.invoiceConfirmation) {
          this._classifiers[code] = this.getName(item);
        } else {
          this._classifiers[code] = this.getValues(item);
        }
      });
    }

    if (classifierTranslations && classifierTranslations.data) {
      classifierTranslations.data.forEach(item => {
        const key = this.getKey(item);
        this._classifierTranslations[key] = this.getTranslation(item);
      });
    }
  };

  getCode = (item: ClassifierItemUnparsed) => _.get(item, 'code');
  getName = (item: ClassifierItemUnparsed) => _.get(item, 'translation');
  getProperties = (item: ClassifierItemUnparsed) => _.get(item, 'properties');
  getValues = (item: ClassifierItemUnparsed) =>
    _.filter(_.get(item, 'lines'), _.get(item, 'active'));

  getKey = (item: ClassifierTranslationItemUnparsed) => _.get(item, 'key');
  getTranslation = (item: ClassifierTranslationItemUnparsed) =>
    _.get(item, 'translation');
}

export default new ClassifiersStore();

export const mapPropertiesToObject = properties => {
  const propertiesObject = {};

  properties.forEach(itemProperty => {
    propertiesObject[itemProperty[classifierGeneralItemPropertiesField.CODE]] =
      itemProperty[classifierGeneralItemPropertiesField.VALUE];
  });

  return propertiesObject;
};
