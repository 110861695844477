import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { Field, reduxForm } from 'redux-form/immutable';
import { NumberField } from '../../atoms';
import { connect } from 'react-redux';
import { Row, Col, Spin, Button, Tooltip, Icon, Alert } from 'antd';
import {
  FORM_MEASUREMENT_DATA,
  measurementDataFormFields,
  dataStatus,
  issuedGosStatus,
} from '../../constants/formsAddEquipment';
import { RegisterActions } from '../../components';
import validate from './validateSchema';
import moment from 'moment';
import {
  addHydrogenMeasurementData,
  fetchHydrogenMeasurementDataForProductionPeriod,
  confirmHydrogenMeasurementData,
  fetchPreviousHydrogenMeasurementSurplus,
  resetPreviousHydrogenMeasurementSurplus,
  fetchHydrogenGosIssued,
  resetHydrogenGosIssued,
} from '../../actions/actionCreators/equipmentAddHydrogenMeasurementDataActionCreators';
import {
  getHydrogenMeasurementData,
  getLoadingMeasurementData,
  getPreviousHydrogenMeasurementSurplus,
  getIsGosIssued,
} from '../../reducers/equipmentAddMeasurementDataReducer';
import { round } from '../../utils/numberHelper';
import { getLocale } from '../../reducers/settingsReducer';
import {
  fetchHydrogenFuelDataForProductionPeriod,
  issueHydrogenGos,
} from '../../actions/actionCreators/productionUnitAddActionCreators';
import { getHydrogenFuelData } from '../../reducers/productionUnitHydrogenFuelReducer';

export class AddEquipmentMeasurementData extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    goNext: PropTypes.func,
    hasPrevious: PropTypes.bool,
    hasDone: PropTypes.bool,
    goPrevious: PropTypes.func,
    productionUnit: PropTypesImmutable.map,
    inEditForNotAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    showRequestChangeModal: PropTypes.func,
    isHydrogenContext: PropTypes.bool,
  };

  getStatus = () => {
    const { measurementData } = this.props;
    if (measurementData) {
      return measurementData.get('status');
    }
  };

  componentDidMount() {
    const {
      productionUnit,
      fetchHydrogenMeasurementDataForProductionPeriod,
      fetchPreviousHydrogenMeasurementSurplus,
      resetSurplus,
      fetchHydrogenFuelDataForProductionPeriod,
      isGosIssued,
      resetGosIssued,
    } = this.props;
    resetSurplus();
    resetGosIssued();
    const productionUnitId = productionUnit.get('id');

    const month =
      moment()
        .subtract(1, 'M')
        .month() + 1;
    const year = moment()
      .subtract(1, 'M')
      .year();

    fetchHydrogenMeasurementDataForProductionPeriod({
      productionUnitId,
      month,
      year,
    });
    fetchHydrogenFuelDataForProductionPeriod({
      productionUnitId,
      year,
      month,
    });
    fetchPreviousHydrogenMeasurementSurplus({ productionUnitId, month, year });
    isGosIssued({
      productionUnitId,
      month,
      year,
    });
  }

  render() {
    const {
      handleSubmit,
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      inEditForNotAdmin,
      isEditMode,
      isAdmin,
      loading,
      showRequestChangeModal,
      measurementData,
      surplus,
      locale,
      proportions,
      gosStatus,
    } = this.props;

    const period = moment()
      .subtract(1, 'M')
      .format('MM.YYYY');
    const canUserSubmit =
      (isAdmin && this.getStatus() !== dataStatus.CONFIRMED) ||
      (!isAdmin && this.getStatus() === dataStatus.OPEN) ||
      (isAdmin &&
        this.getStatus() === dataStatus.CONFIRMED &&
        gosStatus === issuedGosStatus.FAIL);
    const showRequestChanges = !isAdmin && this.getStatus() !== dataStatus.OPEN;

    const totalProducedHydrogen = measurementData?.get('totalProducedHydrogen');
    const rfnboHydrogenGos = measurementData?.get('rfnboHydrogenGos');
    const otherRenewableHydrogenGos = measurementData?.get(
      'otherRenewableHydrogenGos'
    );
    const fossilHydrogenGos = measurementData?.get('fossilHydrogenGos');

    let isCalculatedMeasurementDataPresent =
      totalProducedHydrogen != null &&
      rfnboHydrogenGos != null &&
      otherRenewableHydrogenGos != null &&
      fossilHydrogenGos != null;

    const renderMeasurementData = (label, value) => (
      <div style={{ position: 'relative', width: locale === 'en' ? 180 : 130 }}>
        <b>{label}:</b>
        <div style={{ position: 'absolute', right: -30, top: 0 }}>
          <p>{value}</p>
        </div>
      </div>
    );

    return (
      <div>
        <Spin spinning={loading}>
          <form onSubmit={handleSubmit}>
            <h2>{t('measurementData.title')}</h2>
            <p>{t('production_period') + ': ' + period}</p>
            <div className={`status_${this.getStatus()}`}>
              {t('status')}: {t(this.getStatus())}
            </div>
            <br />
            <h3>{t('measurementData.electricitySubtitle')}</h3>
            <Row gutter={32}>
              <Col span={5} className={'p-1'}>
                <b>
                  {t(
                    `measurementData.${
                      measurementDataFormFields.RFBNO_ELECTRICITY
                    }`
                  )}
                </b>
              </Col>
              <Col span={3}>
                <Field
                  required
                  name={measurementDataFormFields.RFBNO_ELECTRICITY}
                  component={NumberField}
                  props={{
                    disabled: !canUserSubmit,
                    step: 0.001,
                    precision: 3,
                    min: 0,
                    isFloat: true,
                  }}
                />
              </Col>
              <Col span={2} className={'p-1'}>
                {proportions && (
                  <p>
                    {proportions[measurementDataFormFields.RFBNO_ELECTRICITY]}%
                  </p>
                )}
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={5} className={'p-1'}>
                <b>
                  {t(
                    `measurementData.${
                      measurementDataFormFields.OTHER_RENEWABLE_ELECTRICITY
                    }`
                  )}
                </b>
              </Col>
              <Col span={3}>
                <Field
                  required
                  name={measurementDataFormFields.OTHER_RENEWABLE_ELECTRICITY}
                  component={NumberField}
                  props={{
                    disabled: !canUserSubmit,
                    step: 0.001,
                    precision: 3,
                    min: 0,
                    isFloat: true,
                  }}
                />
              </Col>
              <Col span={2} className={'p-1'}>
                {proportions && (
                  <p>
                    {
                      proportions[
                        measurementDataFormFields.OTHER_RENEWABLE_ELECTRICITY
                      ]
                    }%
                  </p>
                )}
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={5} className={'p-1'}>
                <b>
                  {t(
                    `measurementData.${
                      measurementDataFormFields.GRID_ELECTRICITY
                    }`
                  )}
                </b>
              </Col>
              <Col span={3}>
                <Field
                  required
                  name={measurementDataFormFields.GRID_ELECTRICITY}
                  component={NumberField}
                  props={{
                    disabled: !canUserSubmit,
                    step: 0.001,
                    precision: 3,
                    min: 0,
                    isFloat: true,
                  }}
                />
              </Col>
              <Col span={2} className={'p-1'}>
                {proportions && (
                  <p>
                    {proportions[measurementDataFormFields.GRID_ELECTRICITY]}%
                  </p>
                )}
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={5} className={'p-1'}>
                <b>
                  {t(
                    `measurementData.${
                      measurementDataFormFields.FOSSIL_ELECTRICITY
                    }`
                  )}
                </b>
              </Col>
              <Col span={3}>
                <Field
                  required
                  name={measurementDataFormFields.FOSSIL_ELECTRICITY}
                  component={NumberField}
                  props={{
                    disabled: !canUserSubmit,
                    step: 0.001,
                    precision: 3,
                    min: 0,
                    isFloat: true,
                  }}
                />
              </Col>
              <Col span={2} className={'p-1'}>
                {proportions && (
                  <p>
                    {proportions[measurementDataFormFields.FOSSIL_ELECTRICITY]}%
                  </p>
                )}
              </Col>
            </Row>
            <br />
            <Row gutter={32}>
              <Col span={12} className={'p-1'}>
                <h3>{t('measurementData.hydrogenSubtitle')}</h3>
              </Col>
              <Col span={12} className={'p-1'}>
                {isCalculatedMeasurementDataPresent && (
                  <h3>{t('measurementData.goIssuanceSubtitle')}</h3>
                )}
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={5} className={'p-1'}>
                <b>
                  {t(
                    `measurementData.${
                      measurementDataFormFields.PRODUCED_HYDROGEN
                    }`
                  )}
                </b>
              </Col>
              <Col span={3}>
                <Field
                  required
                  name={measurementDataFormFields.PRODUCED_HYDROGEN}
                  component={NumberField}
                  props={{
                    disabled: !canUserSubmit,
                    step: 0.001,
                    precision: 3,
                    min: 0,
                    isFloat: true,
                  }}
                />
              </Col>
              <Col span={4} />
              {isCalculatedMeasurementDataPresent && (
                <Col span={5} className={'p-1'} style={{ textWrap: 'nowrap' }}>
                  {renderMeasurementData(
                    t(
                      `measurementData.${
                        measurementDataFormFields.RFNBO_HYDROGEN
                      }`
                    ),
                    rfnboHydrogenGos
                  )}
                </Col>
              )}
            </Row>
            {isCalculatedMeasurementDataPresent && (
              <>
                <Row gutter={32}>
                  <Col span={5} className={'p-1'}>
                    <b>
                      {t(
                        `measurementData.${measurementDataFormFields.SURPLUS}`
                      )}:
                    </b>
                    <span style={{ marginLeft: 4 }}>
                      <Tooltip title={t(`measurementData.surplusTooltip`)}>
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  </Col>
                  <Col style={{ padding: '8px 28px' }} span={5}>
                    {surplus}
                  </Col>
                  <Col span={2} />
                  <Col
                    span={5}
                    className={'p-1'}
                    style={{ textWrap: 'nowrap' }}
                  >
                    {renderMeasurementData(
                      t(
                        `measurementData.${
                          measurementDataFormFields.OTHER_RENEWABLE_HYDROGEN
                        }`
                      ),
                      otherRenewableHydrogenGos
                    )}
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={5} className={'p-1'}>
                    <b>
                      {t(`measurementData.${measurementDataFormFields.TOTAL}`)}:
                    </b>
                  </Col>
                  <Col style={{ padding: '8px 28px' }} span={5}>
                    {totalProducedHydrogen}
                  </Col>
                  <Col span={2} />
                  <Col
                    span={5}
                    className={'p-1'}
                    style={{ textWrap: 'nowrap' }}
                  >
                    {renderMeasurementData(
                      t(
                        `measurementData.${
                          measurementDataFormFields.FOSSIL_HYDROGEN
                        }`
                      ),
                      fossilHydrogenGos
                    )}
                  </Col>
                </Row>
              </>
            )}

            <RegisterActions
              showRequestChangeModal={showRequestChangeModal}
              showRequestChangeButton={showRequestChanges}
              hasPrevious={hasPrevious}
              hasDone={hasDone}
              isEditMode={isEditMode}
              isAdmin={isAdmin}
              goPrevious={goPrevious}
              showSaveButton={true}
              inEditForNotAdmin={inEditForNotAdmin}
              saveButtonDisabled={
                this.getStatus() === dataStatus.CONFIRMED &&
                gosStatus === issuedGosStatus.SUCCESS
              }
              t={t}
              isMeasurementDataStep={true}
            />

            <div className="component-register-actions">
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                disabled={!canUserSubmit}
              >
                {t('button_save')}
              </Button>
            </div>
            {gosStatus &&
              gosStatus !== issuedGosStatus.NA && (
                <Alert
                  key={gosStatus}
                  style={{ marginTop: 20 }}
                  message={
                    gosStatus === issuedGosStatus.SUCCESS
                      ? t('goSuccess')
                      : t('goFailure')
                  }
                  type={
                    gosStatus === issuedGosStatus.SUCCESS ? 'success' : 'error'
                  }
                />
              )}
          </form>
        </Spin>
      </div>
    );
  }
}

const onSubmit = async (values, dispatch, props) => {
  const data = props.measurementData.toJS();
  const status = data.status;
  const formData = values.toJS();
  const hydrogenFuelData = props.hydrogenFuelData.toJS();

  const postData = {
    ...formData,
    status: status,
    year: data.year,
    month: data.month,
    productionUnit: data.productionUnit,
  };
  if (status === dataStatus.OPEN) {
    dispatch(addHydrogenMeasurementData(postData));
  } else if (
    (status === dataStatus.PENDING ||
      (status === dataStatus.CONFIRMED &&
        props.gosStatus === issuedGosStatus.FAIL)) &&
    props.isAdmin
  ) {
    const measurementData = await dispatch(
      confirmHydrogenMeasurementData(postData)
    );
    if (
      hydrogenFuelData.status === 'CONFIRMED' &&
      measurementData.status === 'CONFIRMED'
    ) {
      const productionUnitId = props.productionUnit.get('id');
      const year = data.year;
      const month = data.month;
      await dispatch(issueHydrogenGos({ productionUnitId, year, month }));
      await dispatch(fetchHydrogenGosIssued({ productionUnitId, month, year }));
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  const existingData = getHydrogenMeasurementData(state);
  const surplus = getPreviousHydrogenMeasurementSurplus(state);
  const isGosIssued = getIsGosIssued(state);
  const issuedGosStatus = isGosIssued?.toJS().data?.status;
  const surplusNr =
    typeof surplus?.toJS().data === 'number' ? surplus?.toJS().data : 0.0;
  let proportions = null;

  if (existingData) {
    const rfbnoAmount = existingData.get(
      measurementDataFormFields.RFBNO_ELECTRICITY
    );
    const otherAmount = existingData.get(
      measurementDataFormFields.OTHER_RENEWABLE_ELECTRICITY
    );
    const gridAmount = existingData.get(
      measurementDataFormFields.GRID_ELECTRICITY
    );
    const fossilAmount = existingData.get(
      measurementDataFormFields.FOSSIL_ELECTRICITY
    );
    if (
      existingData.get('status') !== dataStatus.OPEN &&
      rfbnoAmount &&
      otherAmount &&
      gridAmount &&
      fossilAmount
    ) {
      const totalAmount = rfbnoAmount + otherAmount + gridAmount + fossilAmount;
      const rfbnoElectricityProportion = rfbnoAmount / totalAmount * 100;
      const otherElectricityProportion = otherAmount / totalAmount * 100;
      const gridElectricityProportion = gridAmount / totalAmount * 100;
      const fossilElectricityProportion = fossilAmount / totalAmount * 100;
      proportions = {
        rfbnoElectricity: round(rfbnoElectricityProportion, 2),
        otherRenewableElectricity: round(otherElectricityProportion, 2),
        gridElectricity: round(gridElectricityProportion, 2),
        fossilElectricity: round(fossilElectricityProportion, 2),
      };
    }
  }

  return {
    loading: getLoadingMeasurementData(state),
    initialValues: existingData,
    measurementData: existingData,
    proportions: proportions,
    surplus: surplusNr,
    locale: getLocale(state),
    hydrogenFuelData: getHydrogenFuelData(state),
    gosStatus: issuedGosStatus,
  };
};

const mapDispatchToProps = {
  fetchHydrogenMeasurementDataForProductionPeriod: fetchHydrogenMeasurementDataForProductionPeriod,
  fetchPreviousHydrogenMeasurementSurplus: fetchPreviousHydrogenMeasurementSurplus,
  resetSurplus: resetPreviousHydrogenMeasurementSurplus,
  fetchHydrogenFuelDataForProductionPeriod: fetchHydrogenFuelDataForProductionPeriod,
  isGosIssued: fetchHydrogenGosIssued,
  resetGosIssued: resetHydrogenGosIssued,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_MEASUREMENT_DATA,
    validate,
    enableReinitialize: true,
    onSubmit,
  })(AddEquipmentMeasurementData)
);
