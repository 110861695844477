// @flow

import axios from 'axios';
import {trim} from 'lodash/string';
import {goToLogin, goToCounsumerError403} from '../../utils/gotoLink';
import {has} from 'lodash';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      const isNotConsumerErr = error.response.data?.errorMessage === "NotAuthenticated"
      if (window.top !== window.self && !isNotConsumerErr) {
        goToCounsumerError403();
      } else {
        goToLogin();
      }
    }
    return Promise.reject(error);
  }
);

axios.defaults.__IS_RESPONSE__ = '__IS_RESPONSE__';

const request = (method, url, another = {}) =>
  axios.request({
    url: `/api/v1/${trim(url, '/')}`,
    method,
    timeout: parseInt(process.env.REACT_APP_AXIOS_TIMEOUT, 10),
    headers: {
      'content-type': 'application/json',
    },
    ...another,
  });
const _requestParams = (method) => (url) => (
  params = {},
  another = {}
) =>
  request(method, url, {
    params,
    ...another,
  });
const requestWithLanguage = (method) =>  (url, language) =>
  axios.request({
    url: `/api/v1/${trim(url, '/')}`,
    method,
    headers: {
      'Accept-Language': language,
    },
  });

const _requestPure = (method) => (url) => request(method, url);

const _requestData = (method) => (url) => (
  data = {},
  another = {}
) =>
  request(method, url, {
    data,
    ...another,
  });

export const checkIsResponse = object => has(object, 'config.__IS_RESPONSE__');
export const requestGetParams = _requestParams('get');
export const requestGet = _requestPure('get');
export const requestDeleteParams = _requestParams('delete');
export const requestDelete = _requestPure('delete');
export const requestPost = _requestData('post');
export const requestPut = _requestData('put');
export const getWithLanguage = requestWithLanguage('get')

// methods to implement a post request that takes a lot of time to process on the backend
const requestLongTimeout = (method, url, another = {}) =>
  axios.request({
    url: `/api/v1/${trim(url, '/')}`,
    method,
    timeout: parseInt(process.env.REACT_APP_AXIOS_LONG_TIMEOUT, 10),
    headers: {
      'content-type': 'application/json',
    },
    ...another,
  });

const _requestDataLongTimeout = (method) => (url) => (
  data = {},
  another = {}
) =>
  requestLongTimeout(method, url, {
    data,
    ...another,
  });


export const requestPostLongTimeout = _requestDataLongTimeout('post');
