import { productionUnitFields } from './productionUnit';

export const FORM_GENERAL = 'FORM_ADD_EQUIPMENT_GENERAL';
export const FORM_MEASUREMENT_DATA = 'FORM_MEASUREMENT_DATA';
export const FORM_CONTACT_PERSON = 'FORM_ADD_EQUIPMENT_CONTACT_PERSON';
export const FORM_TECHNICAL_INFORMATION =
  'FORM_ADD_EQUIPMENT_TECHNICAL_INFORMATION';
export const FORM_FUELS_USED = 'FORM_ADD_EQUIPMENT_FUELS_USED';
export const FORM_FUELS_USED_HISTORY = 'FORM_ADD_EQUIPMENT_FUELS_USED_HISTORY';
export const FORM_ACTIVITY_PERIOD = 'FORM_ADD_EQUIPMENT_ACTIVITY_PERIOD';
export const FORM_DOCUMENTS = 'FORM_ADD_EQUIPMENT_DOCUMENTS';

export const generalFormFields = {
  ID: productionUnitFields.ID,
  NAME: productionUnitFields.NAME,
  EICW_CODE: productionUnitFields.EICW_CODE,
  ADDRESS: productionUnitFields.ADDRESS,
  FIRST_TIME_USE: productionUnitFields.FIRST_TIME_USE,
  AMOUNT_OF_INVESTMENT: productionUnitFields.AMOUNT_OF_INVESTMENT,
  IS_INVESTMENT_SUPPORT: productionUnitFields.IS_INVESTMENT_SUPPORT,
  LEGAL_ENTITY_ID: productionUnitFields.LEGAL_ENTITY_ID,
};

export const measurementDataFormFields = {
  ID: 'id',
  MONTH: 'month',
  RFBNO_ELECTRICITY: 'rfbnoElectricity',
  OTHER_RENEWABLE_ELECTRICITY: 'otherRenewableElectricity',
  GRID_ELECTRICITY: 'gridElectricity',
  FOSSIL_ELECTRICITY: 'fossilElectricity',
  PRODUCED_HYDROGEN: 'producedHydrogen',
  SURPLUS: 'surplus',
  TOTAL: 'total',
  RFNBO_HYDROGEN: 'rfnboHydrogen',
  OTHER_RENEWABLE_HYDROGEN: 'otherRenewableHydrogen',
  FOSSIL_HYDROGEN: 'fossilHydrogen',
  RFNBO_HYDROGEN_HISTORY: 'rfnboHydrogenGos',
  OTHER_RENEWABLE_HYDROGEN_HISTORY: 'otherRenewableHydrogenGos',
  FOSSIL_HYDROGEN_HISTORY: 'fossilHydrogenGos',
  SURPLUS_HISTORY: 'historySurplus',
  TOTAL_HISTORY: 'historyTotal',
};
export const contactPersonFormFields = {
  NAME: 'name',
  LAST_NAME: 'lastName',
  PERSONAL_CODE: 'personalCode',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
};

export const dataStatus = {
  OPEN: 'OPEN',
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
};

export const issuedGosStatus = {
  NA: 'NA',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

export const technicalInformationFormFields = {
  CLEANING_TECHNOLOGY_TYPE: productionUnitFields.TECHNOLOGY_TYPE,
  HYDROGEN_TECHNOLOGY_TYPE: productionUnitFields.HYDROGEN_TECHNOLOGY_TYPE,
  ELECTROLYSIS_TECHNOLOGY_TYPE:
    productionUnitFields.ELECTROLYSIS_TECHNOLOGY_TYPE,
  NOMINAL_POWER: productionUnitFields.NOMINAL_POWER,
  METERING_POINT: productionUnitFields.METERING_POINT,
  METERING_POINT_EICZ: productionUnitFields.METERING_POINT_EICZ,
  SELF_SUFFICIENT_WEIGHT: productionUnitFields.SELF_SUFFICIENT_WEIGHT,
  ECONOMIC_CERTIFICATE: productionUnitFields.ECONOMIC_CERTIFICATE,
  CUSTOM_CERTIFICATE_NAME: 'customCertificateName',
  CERTIFICATE_NAME: productionUnitFields.CERTIFICATE_NAME,
  IDS_OF_FILES: productionUnitFields.IDS_OF_FILES,
  UPLOAD_CERTIFICATE: 'uploadCertificate',
  CERTIFICATE_END_DATE: productionUnitFields.CERTIFICATE_END_DATE,
  RFNBO: productionUnitFields.RFNBO,
};

export const fuelsUsedHistoryFormFields = {
  MONTH: 'month',
  YEAR: 'year',
};

export const fuelsUsedFormFields = {
  TYPES: productionUnitFields.FUEL_TYPES,
  ID: 'id',
  IS_FUEL_USED: 'isFuelUsed',
  PERCENTAGE: 'fuelPercentage',
  FUEL_TYPE: 'fuelType',
  POS_NUMBER: 'posNumber',
  FEEDSTOCK: 'feedstock',
  PRODUCTION_PATHWAY: 'productionPathway',
  LOWER_CALORIFIC_VALUE_KG: 'lowerCalorificValueKg',
  HIGHER_CALORIFIC_VALUE_KG: 'higherCalorificValueKg',
  CHG_INTENSITY: 'chgIntensity',
  STATUS: 'status',
  FEEDSTOCK_COUNTRY: 'feedstockCountry',
  PURCHASE_COUNTRY: 'purchaseCountry',
  RAW_MATERIAL_PROPERTIES: 'rawMaterialProperties',
  BIOFUEL_TYPE: 'biofuelType',
  CALCULATED_MULTIPLIER: 'calculatedMultiplier',
  LAND_USE_CATEGORY: 'landUseCategory',
  LAND_USE_EMISSIONS: 'landUseEmissions',
};

export const activityPeriodFormFields = {
  START_DATE: 'subsidyStartDate',
  END_DATE: 'subsidyEndDate',
  ELIGIBLE_FOR_SUBSIDY: 'subsidyEligible',
  DECISION_DATE: 'decisionDate',
  DECISION_NUMBER: 'decisionNumber',
};

export const documentsFormFields = {
  ID: 'id',
  FILE: 'file',
  PRODUCTION_UNIT_ID: 'productionUnitId',
  NAME: 'name',
};

export const certificateFormFields = {
  ID: 'id',
  FILE: 'file',
  PRODUCTION_UNIT_ID: 'productionUnitId',
  NAME: 'name',
};
