import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { TextField, RadioField, DateField, NumberField } from '../../atoms';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import {
  FORM_GENERAL,
  generalFormFields,
} from '../../constants/formsAddEquipment';
import { RegisterActions, AutoCompleteCompanies } from '../../components';
import {
  updateProductionUnit,
  addProductionUnit,
  activateProductionUnit,
  deactivateProductionUnit,
  fetchProductionUnitByEicw,
} from '../../actions/actionCreators/productionUinitActionCreators';
import { fuelType, productionUnitStatus } from '../../constants/classificators';
import { selectOwner as selectorOwnerAction } from '../../actions/actionCreators/productionUnitAddActionCreators';
import { productionUnitFields } from '../../constants/productionUnit';
import validate from './validateSchema';
import { setDuplicateEicwError } from '../../reducers/eicwValidatorReducer';
import './AddEquipmentGeneral.css';

export class AddEquipmentGeneral extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    goNext: PropTypes.func,
    hasPrevious: PropTypes.bool,
    hasDone: PropTypes.bool,
    goPrevious: PropTypes.func,
    isInvestmentSupport: PropTypes.bool,
    productionUnit: PropTypesImmutable.map,
    inEditForNotAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    selectProductionUnitOwner: PropTypes.bool.isRequired,
    legalEntityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showRequestChangeModal: PropTypes.func,
    selectOwner: PropTypes.func.isRequired,
    activate: PropTypes.func.isRequired,
    deactivate: PropTypes.func.isRequired,
    isHydrogenContext: PropTypes.bool,
  };

  render() {
    const {
      handleSubmit,
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      isInvestmentSupport,
      inEditForNotAdmin,
      isEditMode,
      isAdmin,
      selectProductionUnitOwner,
      legalEntityId,
      showRequestChangeModal,
      selectOwner,
      activate,
      deactivate,
      initialValues,
      duplicateEicwError,
      eicwCode,
      isHydrogenContext
    } = this.props;

    const unitStatus = initialValues.get(productionUnitFields.STATUS);
    const unitId = initialValues.get(productionUnitFields.ID);

    const isInvalidEicwCode = eicwCode !== undefined && duplicateEicwError;

    const changesMade =
      initialValues.get(productionUnitFields.EICW_CODE) !== eicwCode;

    return (
      <div>
        {selectProductionUnitOwner && (
          <AutoCompleteCompanies onChange={selectOwner} value={legalEntityId} />
        )}
        <form onSubmit={handleSubmit}>
          <h2>{isEditMode ? t('general.titleEdit') : t('general.title')}</h2>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                label={t('general.name')}
                tooltip={t('general.nameTooltip')}
                required
                name={generalFormFields.NAME}
                component={TextField}
                disabled={inEditForNotAdmin || !legalEntityId}
              />
            </Col>
            {(isEditMode || isAdmin) && (
              <>
                <Col span={12}>
                  <Field
                    name={generalFormFields.EICW_CODE}
                    component={TextField}
                    required={isAdmin}
                    type="text"
                    label={t('general.eicwCode')}
                    disabled={inEditForNotAdmin || !legalEntityId}
                    className={isInvalidEicwCode ? 'eicw-error-field' : ' '}
                  />
                  {isInvalidEicwCode && (
                    <p style={{ color: '#f5222d' }}>
                      {t('general.eicwCodeError')}
                    </p>
                  )}
                </Col>
              </>
            )}
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={generalFormFields.ADDRESS}
                component={TextField}
                required
                type="text"
                label={t('general.address')}
                disabled={inEditForNotAdmin || !legalEntityId}
              />
            </Col>
            <Col span={12}>
              <Field
                className="date-field"
                name={generalFormFields.FIRST_TIME_USE}
                component={DateField}
                required
                label={t('general.firstTimeUse')}
                tooltip={t(isHydrogenContext ? 'general.firstTimeUseTooltipHydrogen' : 'general.firstTimeUseTooltip')}
                disabled={inEditForNotAdmin || !legalEntityId}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={generalFormFields.IS_INVESTMENT_SUPPORT}
                component={RadioField}
                required
                options={[
                  {
                    value: true,
                    label: t('general.isInvestmentSupport.trueLabel'),
                  },
                  {
                    value: false,
                    label: t('general.isInvestmentSupport.falseLabel'),
                  },
                ]}
                type="text"
                label={t('general.isInvestmentSupport.label')}
                disabled={inEditForNotAdmin || !legalEntityId}
              />
            </Col>
            <Col span={12}>
              {isInvestmentSupport && (
                <Field
                  name={generalFormFields.AMOUNT_OF_INVESTMENT}
                  component={NumberField}
                  required
                  type="text"
                  label={t('general.amountOfInvestment')}
                  disabled={inEditForNotAdmin || !legalEntityId}
                />
              )}
            </Col>
          </Row>
          <RegisterActions
            showRequestChangeModal={showRequestChangeModal}
            hasPrevious={hasPrevious}
            hasDone={hasDone}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            goPrevious={goPrevious}
            showSaveButton={isAdmin || !isEditMode}
            inEditForNotAdmin={inEditForNotAdmin}
            t={t}
          >
            {unitId &&
              isEditMode &&
              isAdmin && (
                <Button
                  disabled={changesMade}
                  size="large"
                  type="primary"
                  onClick={
                    unitStatus === productionUnitStatus.ACTIVE
                      ? () => deactivate(unitId)
                      : () => activate(unitId)
                  }
                >
                  {unitStatus === productionUnitStatus.ACTIVE
                    ? t('general.deactivateBtn')
                    : t('general.activateBtn')}
                </Button>
              )}
          </RegisterActions>
        </form>
      </div>
    );
  }
}

const onSubmit = async (values, dispatch, props) => {
  const currentProductionUnitId = values.get(generalFormFields.ID);
  const productionUnitWithGivenEicw = await dispatch(
    fetchProductionUnitByEicw(values.toJS().eicwCode)
  );

  if (
    productionUnitWithGivenEicw.result &&
    currentProductionUnitId !== productionUnitWithGivenEicw.result
  ) {
    dispatch(setDuplicateEicwError(true));
    return null;
  }
  dispatch(setDuplicateEicwError(false));

  await dispatch(
    currentProductionUnitId
      ? updateProductionUnit(values.toJS())
      : addProductionUnit(
          values
            .set(generalFormFields.LEGAL_ENTITY_ID, props.legalEntityId)
            .toJS()
        )
  );
  if (props.goNext) {
    props.goNext();
  }
};

const selector = formValueSelector(FORM_GENERAL);

const mapStateToProps = (state, ownProps) => {
  let initialValues = ownProps.productionUnit;
  if (ownProps.productionUnit.get('productionDeviceType') === undefined) {
    initialValues = ownProps.productionUnit.set(
      'productionDeviceType',
      ownProps.isHydrogenContext ? fuelType.HYDROGEN : fuelType.BIOMETHANE
    );
  }

  return {
    isInvestmentSupport: selector(
      state,
      generalFormFields.IS_INVESTMENT_SUPPORT
    ),
    eicwCode: selector(state, generalFormFields.EICW_CODE),
    initialValues,
    duplicateEicwError: state.toJS().eicwValidatorReducer.duplicateEicwError,
  };
};

const mapDispatchToProps = {
  selectOwner: selectorOwnerAction,
  activate: activateProductionUnit,
  deactivate: deactivateProductionUnit,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_GENERAL,
    validate,
    enableReinitialize: true,
    onSubmit,
  })(AddEquipmentGeneral)
);
