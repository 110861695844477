import { createValidation, validatorRequired } from '../../utils/formValidators';
import { measurementDataFormFields } from '../../constants/formsAddEquipment';

export default createValidation([
  {
    name: measurementDataFormFields.RFBNO_ELECTRICITY,
    validators: [validatorRequired()],
  },
  {
    name: measurementDataFormFields.OTHER_RENEWABLE_ELECTRICITY,
    validators: [validatorRequired()],
  },
  {
    name: measurementDataFormFields.FOSSIL_ELECTRICITY,
    validators: [validatorRequired()],
  },
  {
    name: measurementDataFormFields.GRID_ELECTRICITY,
    validators: [validatorRequired()],
  },
  {
    name: measurementDataFormFields.PRODUCED_HYDROGEN,
    validators: [validatorRequired()],
  },
]);
