import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {reactI18nextModule} from 'react-i18next';

const NAMESPACES = {
  account: 'account',
  measuring: 'measuring',
  common: 'common',
  register: 'register',
  landing: 'landing',
  dashboard: 'dashboard',
  addEquipment: 'addEquipment',
  productionUnitSearch: 'productionUnitSearch',
  meteringPoint: 'meteringPointView',
  consumptionPointAdd: 'consumptionPointAdd',
  meteringPointList: 'meteringPointList',
  consumer: 'consumer',
  transactionSearch: 'transactionSearch',
  transactionView: 'transactionView',
  notification: 'notification',
  requestChange: 'requestChange',
  cancelingCertificates: 'cancelingCertificates',
  transferringCertificates: 'transferringCertificates',
  classifiers: 'classifiers',
  backend: 'backend',
  invoiceView: 'invoiceView',
  invoiceSearch: 'invoiceSearch',
  report: 'report',
  consumerReports: 'consumerReports',
  manualElectricGosIssuing: 'manualElectricGosIssuing',
  manualGoIssuing: 'manualGoIssuing',
  accountGOS: 'accountGOS',
  tradingAccount: 'tradingAccount',
  transferringGOSCertificates: 'transferringGOSCertificates',
  transferringStatistics: 'transferringStatistics',
  transactionGOSView: 'transactionGOSView',
  GOSCertificatesLockView: 'gosCertificatesLockView',
  loadingUnit: 'loadingUnit',
  loadingUnitSearch: 'loadingUnitSearch',
  vdsMessageModal: 'vdsMessageModal',
  tsAgreement: 'tsAgreement',
  agreementView: 'agreementView',
  reservations: 'reservations',
  auctions: 'auctions',
  measurementDataHistory: 'measurementDataHistory'
};

const locales = {
  EN: 'en',
  ET: 'et',
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: locales.ET,
    whitelist: [locales.ET, locales.EN],
    saveMissing: false,
    appendNamespaceToCIMode: true,

    defaultNS: NAMESPACES.common,

    debug: process.env.REACT_APP_TRANS_DEBUG === 'true',

    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true,
    },

    backend: {
      loadPath: '/locale/{{lng}}/{{ns}}.json'
    }
  });

const tCreate = ns => (message, params) => i18n.t(`${ns}:${message}`, params);

const tCommon = tCreate(NAMESPACES.common);
const tRegister = tCreate(NAMESPACES.register);
const tAddEquipment = tCreate(NAMESPACES.addEquipment);
const tMeasuring = tCreate(NAMESPACES.measuring);
const tBackend = tCreate(NAMESPACES.backend);
const tGOS = tCreate(NAMESPACES.GOSCertificatesLockView);

export {
  tCreate,
  tCommon,
  tRegister,
  tAddEquipment,
  tMeasuring,
  tBackend,
  tGOS,
  NAMESPACES,
  locales,
};
export default i18n;
